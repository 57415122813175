
export default {
  loading: {
    type: Boolean,
    default: false
  },

  disabled: {
    type: Boolean,
    default: false
  },

  /**
   * Skeleton loading
   *
   * @prop {'skeleton-img' | 'skeleton-slides'} loadingSkeletonAnimation
   */
  loadingSkeletonAnimation: {
    type: String,
    default: 'skeleton-img',
  },

  /**
   * @see @prop loadingSkeletonAnimation
   */
  loadingSkeletonAnimationSSR: {
    type: String,
    default: null
  },

  loadingSkeletonAspect: {
    type: [Boolean, String],
    default: null
  },

  loadingSkeletonHeight: {
    type: String,
    default: '100%'
  },

  loadingSkeletonWidth: {
    type: String,
    default: '100%'
  },

  loadingSkeletonClass: {
    type: String,
    default: null,
  },

  slideClass: {
    type: String,
    default: ''
  },

  maxSlides: {
    type: Number,
    default: Infinity
  },

  /**
   * @prop {Boolean} preventSwiperUpdate
   * Prevents swiper from reinitializing slides
   * Enabling can prevent lag and stutter, and possibly but not guaranteed introduce issues with updating & adding/removing slides
   */
  preventSwiperUpdate: {
    type: Boolean,
    default: false
  },

  /**
   * @prop {Boolean} navigationPreventDefault
   * Prevents default behaviour when clicking navigation buttons
   */
  navigationPreventDefault: {
    type: Boolean,
    default: false
  },

  swiperContainerStyle: {
    type: [String, Object],
    default: null
  },

  /**
   * @prop {string} dotsType light | dark | standard
   */
  dotsType: {
    type: String,
    default: 'light'
  },

  /**
   * @prop {string} dotsAlign above-bottom | bottom | outside
   */
  dotsAlign: {
    type: String,
    default: 'above-bottom'
  },

  dotsClass: {
    type: String,
    default: null
  },

  /**
   * @prop {string} arrowsType default | circular
   */
  arrowsType: {
    type: String,
    default: 'default'
  },

  /**
   * @prop {string} arrowsSize lg | sm
   */
  arrowsSize: {
    type: String,
    default: 'lg'
  },

  /**
   * @prop {string} arrowsAlign border | outside | outside-xl | outside-xxl | inside
   */
  arrowsAlign: {
    type: String,
    default: 'border'
  },

  // Overrides arrowsType color
  arrowsColor: {
    type: String,
    default: null
  },

  // Display navigation even when not hovering
  constantDisplayNavigation: {
    type: Boolean,
    default: false
  },

  // Always display navigation no matter what
  forceDisplayNavigation: {
    type: Boolean,
    default: false
  },

  // To import Swiper module required for thumbnails to work
  usesThumbs: {
    type: Boolean,
    default: false
  },

  horizontalCenteredSlides: {
    type: Boolean,
    default: false
  },

  dynamicBullets: {
    type: Boolean,
    default: false
  },

  /**
   * - Uneven numbers
   * - Don't go above 8 and avoid going above 5
   */
  dynamicMainBullets: {
    type: Number,
    default: 3
  },

  activeDynamicDotSizePx: {
    type: Number,
    default: 24
  },

  /**
   * IMPORTANT: flickering can occur when this is true and preventSwiperUpdate is false
   */
  onlyRenderVisibleSlides: {
    type: Boolean,
    default: false
  },

  forceEnablePrev: {
    type: Boolean,
    default: false
  },

  forceEnableNext: {
    type: Boolean,
    default: false
  },

  navigationPosition: {
    type: String,
    default: 'overlay'
  },

  /**
   * Swiper options
   *
   * Swiper option name: centeredSlides
   */
  verticalCenteredSlides: {
    type: Boolean,
    default: false
  },

  pagination: {
    type: Boolean,
    default: true
  },

  navigation: {
    type: Boolean,
    default: true
  },

  autoplay: {
    type: [Object, Boolean],
    default: false
  },

  autoHeight: {
    type: Boolean,
    default: false
  },

  slidesPerView: {
    type: Number,
    default: 1
  },

  slidesPerGroup: {
    type: Number,
    default: 1
  },

  speed: {
    type: Number,
    default: 500
  },

  centered: {
    type: Boolean,
    default: false
  },

  rewind: {
    type: Boolean,
    default: false
  },

  loop: {
    type: Boolean,
    default: false
  },

  initialSlide: {
    type: Number,
    default: 0
  },

  thumbs: {
    type: Object,
    default: null
  },

  spaceBetween: {
    type: Number,
    default: 0
  },

  breakpoints: {
    type: Object,
    default: null
  },

  slideToClickedSlide: {
    type: Boolean,
    default: false
  },

  effect: {
    type: String,
    default: null
  },

  observer: {
    type: Boolean,
    default: false
  },

  observeParents: {
    type: Boolean,
    default: false
  },

  nested: {
    type: Boolean,
    default: false
  },

  allowTouchMove: {
    type: Boolean,
    default: true
  },

  coverflowEffect: {
    type: Object,
    default: null
  },

  arrowShadow: {
    type: Boolean,
    default: false
  },
}